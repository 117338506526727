import React, { useEffect, useState, FC } from "react";
import DefaultLayout from "../../layout/DefaultLayout";
import { getCookie } from "cookies-next";
import { Autoplay, Pagination, Navigation } from "swiper";
import { Swiper, SwiperSlide } from "swiper/react";
import {
  Bag,
  BagHappy,
  ChemicalGlass,
  Gift,
  Shop,
  ShoppingCart,
  TruckFast,
} from "iconsax-react";
import Lombok from "../../common/icon/Lombok";
import Daging from "../../common/icon/Daging";

import "swiper/css";
import "swiper/css/pagination";
import "swiper/css/navigation";
import "swiper/css/autoplay";

type InfoTypes = {
  information?: {
    name?: string;
    logo?: {
      lg?: string;
      md?: string;
      sm?: string;
      xs?: string;
    };
    android_download_url: string;
    ios_download_url?: string;
  };
  statistics?: {
    customer?: number;
    products?: number;
    sales?: number;
  };
};

const Home: FC = () => {
  const [content, setContent] = useState<InfoTypes | undefined>();
  const [isAndroid, setIsAndroid] = useState(false);
  const [isIos, setIsIos] = useState(false);
  const [isDekstop, setIsDekstop] = useState(false);

  useEffect(() => {
    const cekAndroid = Boolean(navigator.userAgent.match(/Android/i));
    const cekIos = Boolean(navigator.userAgent.match(/iPhone|iPad|iPod/i));
    const cekMobile = Boolean(cekAndroid || cekIos);
    const cekDesktop = Boolean(!cekMobile);
    setIsAndroid(cekAndroid);
    setIsIos(cekIos);
    setIsDekstop(cekDesktop);
  }, []);

  useEffect(() => {
    const inform: any = getCookie("__GENERAL_INFO");
    if (inform !== undefined) {
      setContent(JSON.parse(inform));
    }
  }, [setContent]);

  return (
    <DefaultLayout typeWrapper="contents" className="overflow-x-hidden">
      <>
        {/* TOP */}
        <div
          className=" bg-local bg-cover md:bg-bottom lg:bg-center bg-no-repeat p-4 lg:p-14 w-full"
          style={{
            backgroundImage: "url('/assets/bg/bg-main.png')",
          }}
        >
          <div className="inline-flex flex-1 flex-col lg:flex-row justify-center lg:justify-around items-start space-y-[3rem] lg:space-y-0 w-full">
            <div className="flex flex-col w-full lg:w-[662px] space-y-6 lg:space-y-8 px-3 pt-4 lg:px-0 items-center lg:items-start text-center lg:text-left ">
              <img
                src={content?.information?.logo?.lg || "/assets/logo-new3.png"}
                className="w-[282px] h-[212px]"
                alt={content?.information?.name || "Sasa Inti"}
              />
              <p className="font-semibold text-[24px] leading-[38px] lg:text-[48px] lg:leading-[58px] capitalize">
                Sasa Store: Solusi belanja <br />
                bumbu masak seru dan
                <br />
                praktis <span className="text-red-500">zaman now!</span> 🌶️✨
              </p>
              <p className="font-normal text-[14px] lg:text-[18px] text-[#1A202C] leading-[16px] lg:leading-[24px]">
                SASA hadir bukan hanya sebagai teman bisnismu, tapi juga sahabat
                untuk berbagai momen dalam hidupmu! Kami berkomitmen memberikan
                solusi terbaik dengan produk bumbu masak berkualitas dan
                kumpulan Resep seru ala Sasa yang selalu update dengan inovasi
                kekinian.
                <br />
                <br />
                Berita seru nih! SASA baru saja merilis SASA Store, tempat
                belanja yang asyik. Di sini, kami nggak hanya menawarkan produk
                unggul, tapi juga memberikan inspirasi untuk menyegarkan
                hidupmu, apapun itu!
              </p>
              <div className="flex flex-1 space-x-3 items-center lg:justify-start justify-center w-full">
                {isAndroid ? (
                  <a
                    href={content?.information?.android_download_url || "/"}
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    <img
                      src="/assets/comp/google-play.png"
                      className="w-[162px] h-[48px] cursor-pointer"
                      alt={content?.information?.name || "Sasa Inti"}
                    />
                  </a>
                ) : (
                  isDekstop && (
                    <a
                      href={content?.information?.android_download_url || "/"}
                      target="_blank"
                      rel="noopener noreferrer"
                    >
                      <img
                        src="/assets/comp/google-play.png"
                        className="w-[162px] h-[48px] cursor-pointer"
                        alt={content?.information?.name || "Sasa Inti"}
                      />
                    </a>
                  )
                )}

                {isIos ? (
                  <a
                    href={content?.information?.ios_download_url || "/"}
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    <img
                      src="/assets/comp/app-store.png"
                      className="w-[162px] h-[48px] cursor-pointer"
                      alt={content?.information?.name || "Sasa Inti"}
                    />
                  </a>
                ) : (
                  isDekstop && (
                    <a
                      href={content?.information?.ios_download_url || "/"}
                      target="_blank"
                      rel="noopener noreferrer"
                    >
                      <img
                        src="/assets/comp/app-store.png"
                        className="w-[162px] h-[48px] cursor-pointer"
                        alt={content?.information?.name || "Sasa Inti"}
                      />
                    </a>
                  )
                )}
              </div>
            </div>
            <div className="flex lg:p-5 lg:w-[730px] relative h-[450px] md:h-[700px] lg:h-auto md:w-full md:justify-center lg:justify-start">
              <div className="relative">
                <img
                  src="/assets/comp/device-hp-3.png"
                  alt={content?.information?.name || "Sasa Inti"}
                  className="h-auto md:h-[750px] lg:h-[730px] lg:w-auto object-contain"
                />

                <div className="hidden lg:block absolute bottom-[20%] right-0">
                  <div className="bg-white p-3 rounded-xl shadow-xl">
                    <img
                      src="/assets/comp/desc-hp.png"
                      alt={content?.information?.name || "Sasa Inti"}
                    />
                  </div>
                </div>
              </div>
              <div className="bg-[#07C0EA] rounded-full absolute left-[12rem] top-[-1rem] lg:hidden w-[14px] h-[14px]"></div>
              <div className="p-[5px] bg-[#FEE1D8] rounded-full absolute top-0 right-5 lg:hidden">
                <Lombok />
              </div>
              <div className="bg-[#F4EB3A] rounded-full absolute left-[6rem] bottom-[2.5rem] lg:hidden w-[28px] h-[28px]"></div>
              <div className="p-[5px] bg-[#FFECD9] rounded-full absolute bottom-0 left-0 lg:hidden">
                <Daging />
              </div>
            </div>
          </div>
          <div className="mt-[8rem] md:mt-[10rem] lg:mt-10" />
        </div>
        {/* TOP */}

        {/* KEUNGGULAN APLIKASI */}
        <div className="mx-6 lg:mx-14 mb-20">
          <div className="flex flex-col space-y-5 justify-center items-center">
            <div
              id="fitur"
              className="flex flex-col flex-1 w-full text-center -mt-20 p-8 bg-white rounded-3xl rounded-b-none space-y-5 justify-center items-center"
            >
              <div className="font-semibold lg:text-[48px] text-[24px] lg:leading-[58px] leading-[34px] text-[#1A202C]">
                Kenapa Ikutan SASA Store?
              </div>
              <p className="w-full lg:w-full lg:max-w-[55rem] font-normal text-[14px] lg:text-[18px] leading-[20px] lg:leading-[24px] text-[#1A202C]">
                Yuk, kunjungi SASA Store, temukan produk pilihan kami, dan
                nikmati pengalaman berbelanja yang menyenangkan. Jadikan SASA
                sebagai sahabat utama di dapurmu dan sumber inspirasi di setiap
                momen hidup.
              </p>
            </div>
            <div className="flex flex-1 flex-col lg:flex-row justify-center lg:justify-between lg:items-center lg:space-x-10 space-y-20 lg:space-y-0 lg:divide-x">
              <div className="flex flex-col items-center justify-center space-y-5 lg:space-y-3 text-center px-10 lg:p-[24px] lg:w-[292.75px] lg:h-[202px]">
                <div className="p-[14px] bg-[#FFF2E5] rounded-[14px]">
                  <BagHappy size="44" color="#FF8A65" variant="Bulk" />
                </div>
                <div className="text-[16px] lg:leading-[20px] leading-[26px] font-medium text-[#1A202C]">
                  Belanja Online
                </div>
                <p className="text-[14px] leading-[20px] font-normal text-[#718096]">
                  Anda bisa membeli bumbu
                  <br /> untuk usaha anda secara online
                </p>
              </div>
              <div className="flex flex-col items-center justify-center space-y-5 lg:space-y-3 text-center px-10 lg:p-[24px] lg:w-[292.75px] lg:h-[202px]">
                <div className="p-[14px] bg-[#FFF2E5] rounded-[14px]">
                  <TruckFast size="44" color="#FF8A65" variant="Bulk" />
                </div>
                <div className="text-[16px] lg:leading-[20px] leading-[26px] font-medium text-[#1A202C]">
                  Pengiriman
                </div>
                <p className="text-[14px] leading-[20px] font-normal text-[#718096]">
                  Pesanan segera dikirimkan ke <br />
                  alamat anda.
                </p>
              </div>
              <div className="flex flex-col items-center justify-center space-y-5 lg:space-y-3 text-center px-10 lg:p-[24px] lg:w-[292.75px] lg:h-[202px]">
                <div className="p-[14px] bg-[#FFF2E5] rounded-[14px]">
                  <ChemicalGlass size="44" color="#FF8A65" variant="Bulk" />
                </div>
                <div className="text-[16px] lg:leading-[20px] leading-[26px] font-medium text-[#1A202C]">
                  Resep Makanan
                </div>
                <p className="text-[14px] leading-[20px] font-normal text-[#718096]">
                  Tips dan Cara memasak <br />
                  masakan terkini.
                </p>
              </div>
              <div className="flex flex-col items-center justify-center space-y-5 lg:space-y-3 text-center px-10 lg:p-[24px] lg:w-[292.75px] lg:h-[202px]">
                <div className="p-[14px] bg-[#FFF2E5] rounded-[14px]">
                  <Gift size="44" color="#FF8A65" variant="Bulk" />
                </div>
                <div className="text-[16px] lg:leading-[20px] leading-[26px] font-medium text-[#1A202C]">
                  Loyalty Rewards Point
                </div>
                <p className="text-[14px] leading-[20px] font-normal text-[#718096]">
                  Banyak poin dan hadiah buat kamu di <br />
                  aplikasi ini
                </p>
              </div>
            </div>
          </div>
        </div>
        {/* KEUNGGULAN APLIKASI */}

        {/* STATISTIK */}
        <div
          id="statistik"
          className=" bg-local bg-cover lg:bg-center bg-no-repeat p-6 lg:px-14 lg:py-28 w-full"
          style={{
            backgroundImage: "url('/assets/bg/bg-blur.png')",
          }}
        >
          <div className="flex flex-col lg:flex-row flex-1 lg:justify-around justify-center items-center ">
            <div className="w-1/6 hidden h-[400px] lg:block relative">
              <div className="p-[18px] bg-[#FFF1EC] rounded-full absolute right-[2rem] top-0">
                <Shop size="44" color="#ef3c52" variant="Bulk" />
              </div>
              <div className="p-[18px] bg-[#EF5DA8] rounded-full absolute right-0 bottom-0">
                <ShoppingCart size="24" color="#FFFFFF" variant="Bulk" />
              </div>
              <div className="p-[18px] bg-[#07C0EA] rounded-full absolute top-[8rem]">
                <Bag size="44" color="#FFFFFF" variant="Bulk" />
              </div>
              <div className="bg-[#EF3C52] rounded-full absolute left-[-2rem] top-[4rem] w-[16px] h-[16px]"></div>
              <div className="bg-[#F4EB3A] rounded-full absolute left-[0] bottom-[2rem] w-[30px] h-[30px]"></div>
            </div>
            <div className="flex flex-col justify-center items-start space-y-[4rem] lg:space-y-10 w-full lg:w-3/5 relative">
              <div className="p-[12px] bg-[#FFF1EC] rounded-full absolute right-[4rem] top-[-3rem] lg:hidden">
                <Shop size="32" color="#EF3C52" variant="Bulk" />
              </div>
              <div className="p-[12px] bg-[#07C0EA] rounded-full absolute top-[8rem] right-[0] lg:hidden">
                <Bag size="32" color="#FFFFFF" variant="Bulk" />
              </div>
              <div className="p-[16px] bg-[#EF5DA8] rounded-full absolute right-0 bottom-[-2rem] lg:hidden">
                <ShoppingCart size="40" color="#FFFFFF" variant="Bulk" />
              </div>
              <div className="bg-[#EF3C52] rounded-full absolute left-[5rem] top-[-4rem] w-[16px] h-[16px] lg:hidden"></div>
              <div className="bg-[#F4EB3A] rounded-full absolute right-[6rem] bottom-[7rem] w-[24px] h-[24px] lg:hidden"></div>
              <div className="bg-[#FFE7DF] rounded-full absolute left-[0] bottom-[-2rem] w-[14px] h-[14px] lg:hidden"></div>
              <p className="font-bold text-[24px] lg:text-[48px] capitalize text-[#1A202C] lg:leading-[58px] leading-[36px]">
                Mari bergabung dengan SASA Store untuk usaha yang lebih baik!
              </p>
              <div className="flex flex-1 flex-col lg:flex-row justify-center lg:justify-between items-start w-full space-y-[3rem] lg:space-y-0">
                <div className="flex flex-col items-start space-y-4 lg:space-y-2">
                  <div className="text-[18px] lg:text-[24px] font-normal text-[#1A202C] leading-[28px]">
                    UMKM Terdaftar
                  </div>
                  <div className="text-[36px] lg:text-[48px] font-bold text-[#EF3C52] lg:leading-[58px] leading-[46px]">
                    {`${content?.statistics?.customer}+` || "0"}
                  </div>
                </div>
                <div className="flex flex-col items-start space-y-4 lg:space-y-2">
                  <div className="text-[18px] lg:text-[24px] font-normal text-[#1A202C] leading-[28px]">
                    Total Produk
                  </div>
                  <div className="text-[48px] font-bold text-[#07C0EA] lg:leading-[58px] leading-[46px]">
                    {`${content?.statistics?.products}+` || "0"}
                  </div>
                </div>
                <div className="flex flex-col items-start space-y-4 lg:space-y-2">
                  <div className="text-[18px] lg:text-[24px] font-normal text-[#1A202C] leading-[28px]">
                    Total Pembelian Perhari
                  </div>
                  <div className="text-[48px] font-bold text-[#3DDB6C] lg:leading-[58px] leading-[46px]">
                    {`${content?.statistics?.sales}+` || "0"}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        {/* STATISTIK */}

        {/* DEVICE */}
        <div className="lg:mx-14 mt-10 lg:mt-40 mb-5 lg:mb-10" id="pratinjau">
          <div
            className="bg-scroll lg:bg-local bg-no-repeat bg-center bg-[length:100%_944px] lg:rounded-[40px] xl:rounded-[40px] hidden lg:block"
            style={{
              backgroundImage: "url('/assets/bg/bg-red.png')",
            }}
          >
            <div className="flex-col justify-center items-center hidden lg:block relative">
              <div className="flex flex-row justify-evenly items-center px-10 lg:-mt-28 ">
                <img
                  src="/assets/device/sasa-device-1.png"
                  className="w-1/4 lg:w-[301px]"
                  alt={content?.information?.name || "Sasa Inti"}
                />
                <img
                  src="/assets/device/sasa-device-2-new.png"
                  className="w-1/4 lg:w-[301px]"
                  alt={content?.information?.name || "Sasa Inti"}
                />
                <img
                  src="/assets/device/sasa-device-3.png"
                  className="w-1/4 lg:w-[301px]"
                  alt={content?.information?.name || "Sasa Inti"}
                />
              </div>
              <div className="flex flex-row justify-evenly items-center px-10 ">
                <img
                  src="/assets/device/sasa-device-4-new.png"
                  className="w-1/4 lg:w-[301px]"
                  alt={content?.information?.name || "Sasa Inti"}
                />
                <img
                  src="/assets/device/sasa-device-5-new.png"
                  className="w-1/4 lg:w-[301px]"
                  alt={content?.information?.name || "Sasa Inti"}
                />
                <img
                  src="/assets/device/sasa-device-6-new.png"
                  className="w-1/4 lg:w-[301px]"
                  alt={content?.information?.name || "Sasa Inti"}
                />
              </div>
            </div>
          </div>
          <div
            className="bg-scroll lg:bg-local bg-no-repeat bg-[length:100%_650px] md:bg-[length:100%_1200px] lg:hidden"
            style={{
              backgroundImage: "url('/assets/bg/bg-mobile.png')",
            }}
          >
            <div className="flex flex-row relative">
              <Swiper
                className="mySwiper"
                modules={[Autoplay, Pagination, Navigation]}
                loop={true}
                autoplay={{ delay: 3000 }}
                pagination={{
                  type: "bullets",
                  clickable: true,
                }}
              >
                <SwiperSlide>
                  <img
                    src="/assets/device/sasa-device-1.png"
                    alt={content?.information?.name || "Sasa Inti"}
                  />
                </SwiperSlide>
                <SwiperSlide>
                  <img
                    src="/assets/device/sasa-device-2-new.png"
                    alt={content?.information?.name || "Sasa Inti"}
                  />
                </SwiperSlide>
                <SwiperSlide>
                  <img
                    src="/assets/device/sasa-device-3.png"
                    alt={content?.information?.name || "Sasa Inti"}
                  />
                </SwiperSlide>
                <SwiperSlide>
                  <img
                    src="/assets/device/sasa-device-4-new.png"
                    alt={content?.information?.name || "Sasa Inti"}
                  />
                </SwiperSlide>
                <SwiperSlide>
                  <img
                    src="/assets/device/sasa-device-5-new.png"
                    alt={content?.information?.name || "Sasa Inti"}
                  />
                </SwiperSlide>
                <SwiperSlide>
                  <img
                    src="/assets/device/sasa-device-6-new.png"
                    alt={content?.information?.name || "Sasa Inti"}
                  />
                </SwiperSlide>
              </Swiper>
            </div>
          </div>
        </div>
        {/* DEVICE */}
      </>
    </DefaultLayout>
  );
};

export default Home;
