import type { NextPage } from "next";
import Head from "next/head";
import { getCookie, setCookies, removeCookies } from "cookies-next";
import Home from "../components/pages/home/Home";
import { client } from "../plugin/axios";

type InfoTypes = {
  data?: {
    information?: {
      name?: string;
      keyword?: string;
      description?: string;
      email?: string;
      phone?: string;
      address?: string;
      icon?: {
        lg?: string;
        md?: string;
        sm?: string;
        xs?: string;
      };
      logo?: {
        lg?: string;
        md?: string;
        sm?: string;
        xs?: string;
      };
      android_download_url?: string;
      ios_download_url?: string;
    };
    statistics?: {
      customer?: number;
      products?: number;
      sales?: number;
    };
    page?: {
      about?: {
        name?: string;
        slug?: string;
      };
      privacy_policy?: {
        name?: string;
        slug?: string;
      };
      tos?: {
        name?: string;
        slug?: string;
      };
      toc?: {
        name?: string;
        slug?: string;
      };
    };
  };
};

const HomePage: NextPage<InfoTypes> = ({ data }) => {
  return (
    <>
      <Head>
        <link rel="icon" href={data?.information?.icon?.md || "/favicon.png"} />
        <title>{data?.information?.name || "Loading"}</title>
        <meta name="description" content={data?.information?.description} />
        <meta name="keywords" content={data?.information?.keyword} />
        <meta name="viewport" content="width=device-width, initial-scale=1.0" />
        <meta property="og:image" content={"/og-image.png"} />
      </Head>
      <Home />
    </>
  );
};

export const getServerSideProps = async ({ req, res }: any) => {
  let getData = null;
  const mode = process.env.NEXT_PUBLIC_MAINTENANCE_MODE as string;
  if (JSON.parse(mode) === true) {
    return {
      redirect: {
        destination: `/maintenance`,
        permanent: false,
      },
    };
  }

  const inform: any = getCookie("__GENERAL_INFO", { req, res });
  if (inform === undefined) {
    await client.get("/information").then((response: any) => {
      getData = response.data;
      setCookies("__GENERAL_INFO", response.data, { req, res, maxAge: 1800 });
    });
  } else {
    getData = JSON.parse(inform);
  }

  return {
    props: {
      data: getData,
    },
  };
};

export default HomePage;
